/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import NewsletterForm from "~components/NewsletterForm";
import SEO from "~components/SEO";

class NewsletterPageComponent extends Component {
  componentDidMount() {}

  render() {
    const { appContext, frontmatter, location } = this.props;

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout className="newsletter-page relative">
          <div className="newsletter-page__inner w-full relative flex items-center justify-center">
            <section>
              <div className="newsletter-page__mailing-list relative grid-end-8 sm:grid-end-9 grid-start-4 sm:grid-start-3 relative mt-24">
                <h3 className="relative flex mb-6 f3">
                  <span
                    className="animation-appear-left block mr-4 whitespace-no-wrap text-black"
                    style={{ animationDelay: `100ms` }}
                  >
                    join our mailing list
                  </span>

                  {appContext.device !== `mobile` && (
                    <span
                      className="animation-appear-right block whitespace-no-wrap text-white"
                      style={{ animationDelay: `200ms` }}
                    >
                      just the good stuff
                    </span>
                  )}
                </h3>

                <NewsletterForm />
              </div>
            </section>
          </div>
        </Layout>
      </>
    );
  }
}

const NewsletterPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <AppContext.Consumer>
      {appContext => (
        <NewsletterPageComponent
          appContext={appContext}
          frontmatter={frontmatter}
          location={location}
        />
      )}
    </AppContext.Consumer>
  );
};

export default NewsletterPage;

export const query = graphql`
  query NewsletterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
  }
`;
